import React from 'react';

export const CalendarIcon: React.FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.2222 5.50488H5.77778C4.79594 5.50488 4 6.18711 4 7.02869V17.6953C4 18.5369 4.79594 19.2191 5.77778 19.2191H18.2222C19.2041 19.2191 20 18.5369 20 17.6953V7.02869C20 6.18711 19.2041 5.50488 18.2222 5.50488Z"
      stroke="#191E80"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.0477 3.98102V7.02862"
      stroke="#191E80"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.95227 3.98102V7.02862"
      stroke="#191E80"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4 10.6095H20"
      stroke="#191E80"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
