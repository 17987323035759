import { createContext } from 'react';
import type { User } from '@/auth/User';

interface IAuthContext {
  user: User | null;
  setUser: (user: User | null) => void;
}

export const AuthContext = createContext<IAuthContext>({
  user: null,
  setUser: () => null,
});
