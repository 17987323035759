import { Button } from '@mui/material';
import React, { type PropsWithChildren } from 'react';

export const TertiaryButton: React.FC<
  PropsWithChildren<{ onClick?: () => void }>
> = ({ children, onClick }) => (
  <Button onClick={onClick} size="large" sx={{ color: 'primary.dark' }}>
    {children}
  </Button>
);
