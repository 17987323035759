import type React from 'react';

export interface ErrorResponse {
  message: string;
}

export const request = async <T>(
  url: RequestInfo,
  token?: string,
  init?: RequestInit,
  ref?: React.MutableRefObject<AbortController | undefined>
): Promise<T> => {
  let options = init;

  if (ref) {
    if (ref.current) {
      ref.current.abort();
    }

    const controller = new AbortController();
    const signal = controller.signal;
    ref.current = controller;
    options = { ...init, signal };
  }

  options = {
    ...options,
    headers: {
      ...options?.headers,
      accept: 'application/json',
      Authorization: token ? `Bearer ${token}` : '',
    },
  };

  const res = await fetch(url, options);

  if (res.ok) {
    const json = (await res.json()) as {
      data: T;
    };

    if (json.data) {
      return json.data;
    } else {
      return json as T;
    }
  } else {
    const json = (await res.json()) as ErrorResponse;
    throw Error(json.message);
  }
};
