import React, { useCallback } from 'react';
import { SliderInput } from '@/components/Inputs';

export const RequestsFilter: React.FC<{
  selectedRequestsRange: number[];
  setSelectedRequestsRange: (range: number[]) => void;
}> = ({ selectedRequestsRange, setSelectedRequestsRange }) => {
  const onRequestsRangeChanged = useCallback(
    (newRange: number[]) => {
      setSelectedRequestsRange(newRange);
    },
    [setSelectedRequestsRange]
  );

  return (
    <SliderInput
      initialRange={[0, 20000]}
      label="Antal kald"
      onChange={onRequestsRangeChanged}
      value={selectedRequestsRange}
    />
  );
};
