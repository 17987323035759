import { Stack, Typography } from '@mui/material';
import React, { useCallback } from 'react';
import { Outlet } from 'react-router-dom';
import { TertiaryButton } from '@/components/Buttons';
import { SubNavBar } from '@/components/NavBar';

const navItems = [
  {
    label: 'Organisationer',
    route: 'organisations',
  },
  {
    label: 'Moduler',
    route: 'modules',
  },
];

export const UserBehaviourLayout: React.FC = () => {
  const onFiltersCleared = useCallback(() => {
    window.location.reload();
  }, []);

  return (
    <Stack>
      <Stack direction="row">
        <Typography flexGrow={1} variant="h1">
          Brugeradfærd
        </Typography>
        <Stack sx={{ mx: 3 }} justifyContent="center">
          <TertiaryButton onClick={onFiltersCleared}>
            Ryd alle filtre
          </TertiaryButton>
        </Stack>
        <SubNavBar navItems={navItems} />
      </Stack>
      <Outlet />
    </Stack>
  );
};
