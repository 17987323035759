import type { SelectChangeEvent } from '@mui/material';
import React, { useCallback } from 'react';
import useSWR from 'swr';
import { ListInput } from '@/components/Inputs';
import { useAuth } from '@/hooks/useAuth';
import { request } from '@/lib/api/request';

export interface ClientSystem {
  id: string;
  name: string;
}

export const ClientSystemFilter: React.FC<{
  selectedClientSystems: string[];
  setSelectedClientSystems: (systems: string[]) => void;
}> = ({ selectedClientSystems, setSelectedClientSystems }) => {
  const { user } = useAuth();

  const { data: clientSystems } = useSWR<ClientSystem[]>(
    [`${process.env.REACT_APP_API_BASE_URL}/api/systems`, user?.accessToken],
    ([url, token]: [RequestInfo, string]) => request(url, token),
    {
      fallbackData: [],
      revalidateOnFocus: false,
    }
  );

  const onClientSystemsChanged = useCallback(
    (event: SelectChangeEvent<string[]>) => {
      const value = event.target.value;

      setSelectedClientSystems(
        typeof value === 'string' ? value.split(',') : value
      );
    },
    [setSelectedClientSystems]
  );

  return (
    <ListInput
      label="Anvendelsessystem"
      numberOfValuesToShow={1}
      onChange={onClientSystemsChanged}
      selectItems={clientSystems!.map((system) => ({
        id: system.id,
        label: system.name,
      }))}
      value={selectedClientSystems}
    />
  );
};
