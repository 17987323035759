import { Stack } from '@mui/material';
import React, { type PropsWithChildren } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useAuth } from '@/hooks/useAuth';
import { defaultGradient } from '@/lib/styles/theme';
import { AnalyticsLayout } from '@/pages/analytics/AnalyticsLayout';
import { NotFoundPage } from '@/pages/analytics/NotFoundPage';
import { OverviewPage } from '@/pages/analytics/overview/OverviewPage';
import { ModulesPage } from '@/pages/analytics/user-behaviour/ModulesPage';
import { OrganisationsPage } from '@/pages/analytics/user-behaviour/OrganisationsPage';
import { UserBehaviourLayout } from '@/pages/analytics/user-behaviour/UserBehaviourLayout';
import { LoginPage } from '@/pages/auth/LoginPage';

const ProtectedRoute: React.FC<PropsWithChildren> = ({ children }) => {
  const { user, isLoading } = useAuth();

  if (!isLoading) {
    if (user?.accessToken) {
      return <Stack>{children}</Stack>;
    } else {
      return <Navigate to={'login'} replace />;
    }
  } else {
    return null;
  }
};

export const App: React.FC = () => (
  <Stack height="100%" minHeight="100vh" sx={{ background: defaultGradient }}>
    <Routes>
      <Route
        path=""
        element={<Navigate to={'user-behaviour/organisations'} replace />}
      />
      <Route path="login" element={<LoginPage />} />
      <Route
        element={
          <ProtectedRoute>
            <AnalyticsLayout />
          </ProtectedRoute>
        }
      >
        <Route path="overview" element={<OverviewPage />} />
        <Route path="user-behaviour" element={<UserBehaviourLayout />}>
          <Route path="organisations" element={<OrganisationsPage />} />
          <Route path="modules" element={<ModulesPage />} />
        </Route>
        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </Routes>
  </Stack>
);
