import React, { useCallback } from 'react';
import useSWR from 'swr';
import { SearchInput } from '@/components/Inputs/SearchInput';
import { useAuth } from '@/hooks/useAuth';
import { request } from '@/lib/api/request';

export interface Organisation {
  id: string;
  name: string;
}

export const OrganisationFilter: React.FC<{
  selectedOrganisation: string;
  setSelectedOrganisation: (organisation: string) => void;
}> = ({ selectedOrganisation, setSelectedOrganisation }) => {
  const { user } = useAuth();

  const { data: organisations } = useSWR<Organisation[]>(
    [
      `${process.env.REACT_APP_API_BASE_URL}/api/organisations`,
      user?.accessToken,
    ],
    ([url, token]: [RequestInfo, string]) => request(url, token),
    {
      fallbackData: [],
      revalidateOnFocus: false,
    }
  );

  const onOrganisationChanged = useCallback(
    (
      event: React.SyntheticEvent<Element, Event>,
      newValue:
        | string
        | {
            id: string;
            label: string;
            firstLetter: string;
          }
        | null
    ) => {
      let id = '';
      if (typeof newValue === 'string') {
        id =
          organisations!.find((organisation) =>
            organisation.name.toLowerCase().includes(newValue.toLowerCase())
          )?.id ?? newValue;
      } else if (newValue) {
        id = newValue.id;
      }

      setSelectedOrganisation(id);
    },
    [organisations, setSelectedOrganisation]
  );

  return (
    <SearchInput
      autocompleteItems={organisations!.map((organisation) => ({
        id: organisation.id,
        label: organisation.name,
      }))}
      label="Organisation"
      onChange={onOrganisationChanged}
      value={selectedOrganisation}
    />
  );
};
