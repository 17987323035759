import { Autocomplete, InputAdornment, TextField } from '@mui/material';
import isEmpty from 'is-empty';
import React from 'react';
import { SearchIcon } from '@/components/Icons';

export const SearchInput: React.FC<{
  autocompleteItems: { id: string; label: string }[];
  label: string;
  onChange: (
    event: React.SyntheticEvent<Element, Event>,
    value: string | { id: string; label: string; firstLetter: string } | null
  ) => void;
  value: string;
}> = ({ autocompleteItems, label, onChange, value }) => {
  const options = autocompleteItems.map((option) => {
    const firstLetter = option.label[0].toUpperCase();
    return {
      ...option,
      firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
    };
  });

  return (
    <Autocomplete
      freeSolo
      groupBy={(option) => option.firstLetter}
      ListboxProps={{
        sx: {
          '.MuiAutocomplete-groupLabel': {
            borderBottom: 1,
            borderColor: 'divider',
          },
        },
      }}
      onChange={onChange}
      options={options.sort(
        (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          label={isEmpty(value) ? '' : label}
          placeholder={label}
          sx={{ width: 320 }}
        />
      )}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.id}>
            {option.label}
          </li>
        );
      }}
      selectOnFocus
      value={
        autocompleteItems.find((item) => item.id === value)?.label ?? value
      }
    />
  );
};
