import { Stack, Typography } from '@mui/material';
import React from 'react';

export const LoadingIndicator: React.FC = () => {
  return (
    <Stack alignItems="center" height="60vh" justifyContent="center">
      <Typography>Loading...</Typography>
    </Stack>
  );
};
