import type { SelectChangeEvent } from '@mui/material';
import React, { useCallback } from 'react';
import useSWR from 'swr';
import { ListInput } from '@/components/Inputs';
import { useAuth } from '@/hooks/useAuth';
import { request } from '@/lib/api/request';

export const ModulesFilter: React.FC<{
  selectedModules: string[];
  setSelectedModules: (modules: string[]) => void;
}> = ({ selectedModules, setSelectedModules }) => {
  const { user } = useAuth();

  const { data: modules } = useSWR<string[]>(
    [`${process.env.REACT_APP_API_BASE_URL}/api/modules`, user?.accessToken],
    ([url, token]: [RequestInfo, string]) => request(url, token),
    {
      fallbackData: [],
      revalidateOnFocus: false,
    }
  );

  const onModulesChanged = useCallback(
    (event: SelectChangeEvent<string[]>) => {
      const value = event.target.value;

      setSelectedModules(typeof value === 'string' ? value.split(',') : value);
    },
    [setSelectedModules]
  );

  return (
    <ListInput
      label="Moduler"
      numberOfValuesToShow={3}
      onChange={onModulesChanged}
      selectItems={modules!.map((name) => ({
        id: name,
        label: name,
      }))}
      value={selectedModules}
    />
  );
};
