import type { Dayjs } from 'dayjs';
import React, { useCallback } from 'react';
import { DateInput } from '@/components/Inputs';

export const EndDateFilter: React.FC<{
  selectedEndDate: Dayjs | null;
  setSelectedEndDate: (date: Dayjs | null) => void;
}> = ({ selectedEndDate, setSelectedEndDate }) => {
  const onEndDateChanged = useCallback(
    (newDate: Dayjs | null) => {
      setSelectedEndDate(newDate);
    },
    [setSelectedEndDate]
  );

  return (
    <DateInput
      label="Slutdato"
      onChange={onEndDateChanged}
      value={selectedEndDate}
    />
  );
};
