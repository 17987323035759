import {
  Dialog as MuiDialog,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import React, { type PropsWithChildren } from 'react';
import { CloseIcon } from '@/components/Icons';
import { defaultGradient } from '@/lib/styles/theme';

export const Dialog: React.FC<
  PropsWithChildren<{
    onClose: () => void;
    open: boolean;
    size?: 'small' | 'large';
    title: string;
  }>
> = ({ children, onClose, open, size = 'large', title }) => (
  <MuiDialog
    fullWidth
    onClose={onClose}
    open={open}
    PaperProps={{
      sx: {
        background: size === 'large' ? defaultGradient : 'background.default',
        borderRadius: 8,
        maxHeight: '88vh',
        maxWidth: size === 'large' ? `calc(100% - 100px)` : 400,
      },
    }}
  >
    <Stack
      alignItems="center"
      borderBottom={1}
      borderColor="divider"
      direction="row"
      justifyContent="space-between"
      px={7}
      py={4}
    >
      <Typography variant="h2">{title}</Typography>
      <IconButton onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </Stack>
    <Stack m={8}>{children}</Stack>
  </MuiDialog>
);
