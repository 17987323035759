import { Divider } from '@mui/material';
import React from 'react';

export const NavBarDivider: React.FC<{
  marginLeft?: number;
  marginRight?: number;
}> = ({ marginLeft = 0, marginRight = 0 }) => (
  <Divider
    orientation="vertical"
    sx={{
      height: 20,
      marginLeft: marginLeft,
      marginRight: marginRight,
      my: 6,
    }}
  />
);
