import React from 'react';
import { NoResultsSection } from '@/components/NoResults/NoResultsSection';

export const NoDataFound: React.FC<{ onClearFilters: () => void }> = ({
  onClearFilters,
}) => (
  <NoResultsSection
    buttonLabel="Ryd alle filtre"
    caption="Ingen resultater fundet"
    onButtonClick={onClearFilters}
    subTitle="Prøv at rediger din søgning eller ryd dine valgte filtre"
  />
);
