import { Button } from '@mui/material';
import React, { type PropsWithChildren } from 'react';

export const UnderlinedButton: React.FC<
  PropsWithChildren<{
    height?: number;
    icon?: React.ReactNode;
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    selected: boolean;
  }>
> = ({ children, height = 48, icon, onClick, selected }) => (
  <Button
    onClick={onClick}
    size="large"
    startIcon={icon}
    sx={{
      borderBottom: selected ? 2 : 0,
      borderColor: 'primary.main',
      borderRadius: 0,
      color: 'primary.dark',
      height: height,
      width: '100%',
      px: 6,
      py: 4,
      ':hover': {
        borderColor: '#C3C5F3',
      },
    }}
  >
    {children}
  </Button>
);
