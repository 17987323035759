import { Button } from '@mui/material';
import React, { type PropsWithChildren } from 'react';

export const PrimaryButton: React.FC<
  PropsWithChildren<{
    disabled?: boolean;
    onClick?: () => void;
  }>
> = ({ children, disabled = false, onClick }) => (
  <Button
    color="primary"
    disabled={disabled}
    fullWidth
    onClick={onClick}
    size="large"
    variant="outlined"
  >
    {children}
  </Button>
);
