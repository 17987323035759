import { ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import React, { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import type { NavItem } from '@/components/NavBar/NavItem';
import { defaultBoxShadow } from '@/lib/styles/theme';

export const SubNavBar: React.FC<{ navItems: NavItem[] }> = ({ navItems }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const onItemClicked = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>, route: string) => {
      navigate(route);
    },
    [navigate]
  );

  return (
    <ToggleButtonGroup
      exclusive
      sx={{ backgroundColor: 'background.paper', borderRadius: 8 }}
      value={location.pathname.split('/')[2]}
    >
      {navItems.map((item) => (
        <ToggleButton
          key={item.route}
          onClick={onItemClicked}
          sx={{
            backgroundColor: 'background.paper',
            border: 0,
            borderRadius: '4px !important',
            margin: 2,
            width: 128,
            ':hover': {
              backgroundColor: 'background.paper',
            },
            '&.Mui-selected': {
              backgroundColor: 'background.default',
              boxShadow: defaultBoxShadow,
            },
            '&.Mui-selected:hover': {
              backgroundColor: 'background.default',
            },
          }}
          value={item.route}
        >
          <Typography
            sx={{ color: 'primary.dark', fontSize: 12, fontWeight: 600, px: 5 }}
          >
            {item.label}
          </Typography>
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};
