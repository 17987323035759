import { Stack } from '@mui/material';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { GraphIcon, TableIcon } from '@/components/Icons';
import { NavBar } from '@/components/NavBar';

const navigationItems = [
  {
    icon: <GraphIcon />,
    label: 'Overblik',
    route: '/overview',
  },
  {
    icon: <TableIcon />,
    label: 'Brugeradfærd',
    route: '/user-behaviour/organisations',
  },
];

export const AnalyticsLayout: React.FC = () => {
  return (
    <Stack>
      <NavBar navItems={navigationItems} />
      <Stack px={11} py={7}>
        <Outlet />
      </Stack>
    </Stack>
  );
};
