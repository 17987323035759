import { KeyboardArrowDownRounded } from '@mui/icons-material';
import {
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  type SelectChangeEvent,
  Typography,
} from '@mui/material';
import React, { useCallback } from 'react';
import { CheckedIcon, UncheckedIcon } from '@/components/Icons';

export const ListInput: React.FC<{
  label: string;
  numberOfValuesToShow: number;
  onChange: (event: SelectChangeEvent<string[]>) => void;
  selectItems: { id: string; label: string }[];
  value: string[];
}> = ({ label, numberOfValuesToShow, onChange, selectItems, value }) => {
  const renderValue = useCallback(
    (selectedIds: string[]) => {
      return selectedIds.map((id, index) => {
        const label = selectItems.find((item) => item.id === id)?.label;
        return index === 0
          ? label
          : index < numberOfValuesToShow
            ? `, ${label}`
            : index === numberOfValuesToShow
              ? ` +${value.length - numberOfValuesToShow}`
              : '';
      });
    },
    [numberOfValuesToShow, selectItems, value.length]
  );

  return (
    <FormControl fullWidth>
      <InputLabel>{label}</InputLabel>
      <Select
        input={<OutlinedInput label={label} />}
        IconComponent={KeyboardArrowDownRounded}
        MenuProps={{
          anchorOrigin: {
            horizontal: 'right',
            vertical: 'bottom',
          },
          MenuListProps: {
            autoFocusItem: false,
            sx: {
              maxHeight: 350,
              minWidth: 250,
            },
          },
          transformOrigin: {
            horizontal: 'right',
            vertical: 'top',
          },
        }}
        multiple
        onChange={onChange}
        renderValue={renderValue}
        value={value}
      >
        {selectItems.map((item) => (
          <MenuItem key={item.id} value={item.id}>
            <Checkbox
              checked={value.includes(item.id)}
              checkedIcon={<CheckedIcon />}
              icon={<UncheckedIcon />}
            />
            <Typography variant="h5">{item.label}</Typography>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
