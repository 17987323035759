import React from 'react';

export const UncheckedIcon: React.FC = () => (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Frame 27">
      <path
        id="Rectangle 1"
        d="M1 4C1 2.067 2.567 0.5 4.5 0.5H16.5C18.433 0.5 20 2.067 20 4V16C20 17.933 18.433 19.5 16.5 19.5H4.5C2.567 19.5 1 17.933 1 16V4Z"
        fill="white"
        stroke="#666666"
      />
    </g>
  </svg>
);
