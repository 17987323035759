import { Stack, TextField, Typography } from '@mui/material';
import isEmpty from 'is-empty';
import React, { useCallback, useState } from 'react';
import { PrimaryButton, SecondaryButton } from '@/components/Buttons';
import { useAuth } from '@/hooks/useAuth';
import { Dialog } from './Dialog';

export const ChangePasswordDialog: React.FC<{
  onClose: () => void;
  open: boolean;
}> = ({ onClose, open }) => {
  const [error, setError] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [repeatedPassword, setRepeatedPassword] = useState('');
  const { changePassword } = useAuth();

  const onChangePasswordClicked = useCallback(async () => {
    if (newPassword !== repeatedPassword) {
      setError('Passwords er ikke ens.');
      return;
    }

    if (
      !/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/.test(
        newPassword
      )
    ) {
      setError(
        'Passwordet skal mindst være 8 karakterer langt. Det skal som minimum indeholde et lille og et stort bogstav, et tal og et specialtegn.'
      );
      return;
    }

    try {
      await changePassword(oldPassword, newPassword);

      onClose();
    } catch {
      setError('Forkert gammelt password.');
    }
  }, [newPassword, oldPassword, onClose, repeatedPassword, changePassword]);

  const onClearNewPasswordClicked = useCallback(() => {
    setNewPassword('');
    setRepeatedPassword('');
    setError('');
  }, []);

  const onNewPasswordChanged = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setNewPassword(event.target.value);
    },
    []
  );

  const onOldPasswordChanged = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setOldPassword(event.target.value);
    },
    []
  );

  const onRepeatPasswordChanged = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setRepeatedPassword(event.target.value);
    },
    []
  );

  return (
    <Dialog
      onClose={onClose}
      open={open}
      size="small"
      title="Ændre dit password"
    >
      <Stack my={3}>
        <Stack spacing={5}>
          <TextField
            autoComplete="new-password"
            fullWidth
            label="Gammelt password"
            onChange={onOldPasswordChanged}
            required
            type="password"
            value={oldPassword}
          />
          <TextField
            autoComplete="new-password"
            fullWidth
            label="Nyt password"
            onChange={onNewPasswordChanged}
            required
            type="password"
            value={newPassword}
          />
          <TextField
            autoComplete="new-password"
            fullWidth
            label="Gentag password"
            onChange={onRepeatPasswordChanged}
            required
            type="password"
            value={repeatedPassword}
          />
        </Stack>
        {error && (
          <Typography color={'secondary.dark'} mt={6} variant={'h4'}>
            {error}
          </Typography>
        )}
        <Stack direction="row" mt={7} spacing={4}>
          <SecondaryButton
            disabled={isEmpty(newPassword) && isEmpty(repeatedPassword)}
            onClick={onClearNewPasswordClicked}
          >
            Ryd nyt password
          </SecondaryButton>
          <PrimaryButton
            disabled={
              isEmpty(oldPassword) ||
              isEmpty(newPassword) ||
              isEmpty(repeatedPassword)
            }
            onClick={onChangePasswordClicked}
          >
            Ændre password
          </PrimaryButton>
        </Stack>
      </Stack>
    </Dialog>
  );
};
