import { useUser } from '@/hooks/useUser';
import { request } from '@/lib/api/request';

interface CognitoResponse {
  AuthenticationResult: {
    AccessToken: string;
    RefreshToken?: string;
  };
}

export const useAuth = () => {
  const commonHeaders = {
    accept: 'application/json',
    'Content-Type': 'application/x-amz-json-1.1',
  };
  const { user, isLoading, addUser, removeUser } = useUser();

  const login = async (username: string, password: string) => {
    const result = (
      await request<CognitoResponse>(
        `${process.env.REACT_APP_COGNITO_URL}`,
        undefined,
        {
          method: 'POST',
          headers: {
            ...commonHeaders,
            'X-Amz-Target': 'AWSCognitoIdentityProviderService.InitiateAuth',
          },
          body: JSON.stringify({
            AuthFlow: 'USER_PASSWORD_AUTH',
            ClientId: process.env.REACT_APP_CLIENT_ID,
            AuthParameters: {
              USERNAME: username,
              PASSWORD: password,
            },
          }),
        }
      )
    ).AuthenticationResult;

    if (!result) {
      throw new Error('An error occurred while logging in');
    } else {
      addUser({
        accessToken: result.AccessToken,
        refreshToken: result.RefreshToken!,
      });
    }
  };

  const logout = () => {
    removeUser();
  };

  const refreshAccessToken = async () => {
    const result = (
      await request<CognitoResponse>(
        `${process.env.REACT_APP_COGNITO_URL}`,
        undefined,
        {
          method: 'POST',
          headers: {
            ...commonHeaders,
            'X-Amz-Target': 'AWSCognitoIdentityProviderService.InitiateAuth',
          },
          body: JSON.stringify({
            AuthFlow: 'REFRESH_TOKEN',
            ClientId: process.env.REACT_APP_CLIENT_ID,
            AuthParameters: {
              REFRESH_TOKEN: user?.refreshToken,
            },
          }),
        }
      )
    ).AuthenticationResult;

    if (!result) {
      removeUser();
    } else {
      addUser({
        accessToken: result.AccessToken,
        refreshToken: result.RefreshToken ?? user?.refreshToken,
      });
    }
  };

  const changePassword = async (oldPassword: string, newPassword: string) => {
    await request(`${process.env.REACT_APP_COGNITO_URL}`, undefined, {
      method: 'POST',
      headers: {
        ...commonHeaders,
        'X-Amz-Target': 'AWSCognitoIdentityProviderService.ChangePassword',
      },
      body: JSON.stringify({
        AccessToken: user?.accessToken,
        PreviousPassword: oldPassword,
        ProposedPassword: newPassword,
      }),
    });
  };

  return { user, isLoading, login, logout, refreshAccessToken, changePassword };
};
