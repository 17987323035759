import React from 'react';

export const CheckedIcon: React.FC = () => (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Frame 27">
      <path
        id="Rectangle 1"
        d="M0.5 4C0.5 1.79086 2.29086 0 4.5 0H16.5C18.7091 0 20.5 1.79086 20.5 4V16C20.5 18.2091 18.7091 20 16.5 20H4.5C2.29086 20 0.5 18.2091 0.5 16V4Z"
        fill="#2931D6"
      />
      <path
        id="Vector"
        d="M14.4998 7.5L8.99995 12.9999L6.5 10.4999"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
