import { Stack } from '@mui/material';
import React, { useCallback } from 'react';
import useSWR from 'swr';
import { UnderlinedButton } from '@/components/Buttons';
import { useAuth } from '@/hooks/useAuth';
import { request } from '@/lib/api/request';

export const ModulesTabBarFilter: React.FC<{
  selectedModules: string[];
  setSelectedModules: (modules: string[]) => void;
}> = ({ selectedModules, setSelectedModules }) => {
  const { user } = useAuth();

  const { data: modules } = useSWR<string[]>(
    [`${process.env.REACT_APP_API_BASE_URL}/api/modules`, user?.accessToken],
    ([url, token]: [RequestInfo, string]) => request(url, token),
    {
      fallbackData: [],
      revalidateOnFocus: false,
    }
  );

  const onModuleClicked = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      const value = event.currentTarget.textContent;

      if (value) {
        setSelectedModules([value]);
      }
    },
    [setSelectedModules]
  );

  return (
    <Stack
      direction="row"
      mt={6}
      sx={{
        borderBottom: 1,
        borderColor: 'dividerDark',
        height: 47,
      }}
    >
      {modules!.map((module) => (
        <UnderlinedButton
          key={module}
          onClick={onModuleClicked}
          selected={module === selectedModules[0]}
        >
          {module}
        </UnderlinedButton>
      ))}
    </Stack>
  );
};
