import { Stack, Typography } from '@mui/material';
import React from 'react';

export const ActiveChip: React.FC<{ label: string; active: boolean }> = ({
  label,
  active,
}) => (
  <Stack
    alignItems="center"
    bgcolor={active ? 'secondary.dark' : 'grey.800'}
    borderRadius={2}
    height={28}
    justifyContent="center"
    width={34}
  >
    {active ? (
      <Typography color="secondary.contrastText" variant="h6">
        {label}
      </Typography>
    ) : (
      <Typography
        color="text.disabled"
        sx={{
          position: 'relative',
          ':after': {
            backgroundColor: 'grey.500',
            borderRadius: 1,
            content: "''",
            height: 1.25,
            left: -3,
            opacity: 0.7,
            position: 'absolute',
            right: -3,
            top: '50%',
          },
        }}
        variant="h6"
      >
        {label}
      </Typography>
    )}
  </Stack>
);
