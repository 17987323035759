import { Box, Dialog, Stack, TextField, Typography } from '@mui/material';
import isEmpty from 'is-empty';
import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PrimaryButton } from '@/components/Buttons';
import { useAuth } from '@/hooks/useAuth';
import { defaultBoxShadow } from '@/lib/styles/theme';

export const LoginPage: React.FC = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { login } = useAuth();

  const onUsernameChanged = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setUsername(event.target.value);
    },
    []
  );

  const onPasswordChanged = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setPassword(event.target.value);
    },
    []
  );

  const onLoginClicked = useCallback(async () => {
    try {
      await login(username, password);

      navigate('/user-behaviour/organisations');
    } catch {
      setError('Forkert brugernavn eller password.');
    }
  }, [login, username, password, navigate]);

  return (
    <Dialog
      disableEscapeKeyDown
      fullWidth
      hideBackdrop
      open={true}
      PaperProps={{
        sx: {
          border: 1,
          borderColor: 'divider',
          borderRadius: 8,
          boxShadow: defaultBoxShadow,
          maxWidth: 400,
        },
      }}
    >
      <Stack
        alignItems="center"
        borderBottom={1}
        borderColor="dividerLight"
        justifyContent="center"
        py={5}
      >
        <Box alt="FMB" component="img" height={32} src="/images/logo.png" />
      </Stack>
      <Stack mx={8} my={9}>
        <TextField
          fullWidth
          label="Brugernavn"
          onChange={onUsernameChanged}
          required
          type="text"
          value={username}
        />
        <TextField
          autoComplete="new-password"
          fullWidth
          label="Password"
          onChange={onPasswordChanged}
          required
          sx={{ mt: 5 }}
          type="password"
          value={password}
        />
        {error && (
          <Typography color={'secondary.dark'} mt={6} variant={'h4'}>
            {error}
          </Typography>
        )}
        <Stack mt={7}>
          <PrimaryButton
            disabled={isEmpty(username) || isEmpty(password)}
            onClick={onLoginClicked}
          >
            Log ind
          </PrimaryButton>
        </Stack>
      </Stack>
    </Dialog>
  );
};
