import {
  KeyboardArrowDownRounded,
  KeyboardArrowUpRounded,
} from '@mui/icons-material';
import {
  InputAdornment,
  Popover,
  Slider,
  Stack,
  TextField,
} from '@mui/material';
import isEmpty from 'is-empty';
import React, { useCallback, useState } from 'react';
import { formatNumber } from '@/lib/formatting/formatNumber';
import { defaultBoxShadow } from '@/lib/styles/theme';

export const SliderInput: React.FC<{
  initialRange: number[];
  label: string;
  onChange: (range: number[]) => void;
  value: number[];
}> = ({ initialRange, label, onChange, value }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const open = Boolean(anchorEl);

  const onInputClicked = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      setAnchorEl(event.currentTarget);
    },
    []
  );

  const onPopOverClosed = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const onMinValueChanged = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const newValue = Number(event.target.value);

      if (!isNaN(newValue)) {
        onChange([newValue, value[1] ?? initialRange[1]]);
      }
    },
    [initialRange, onChange, value]
  );

  const onMaxValueChanged = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const newValue = Number(event.target.value);

      if (!isNaN(newValue)) {
        onChange([value[0] ?? initialRange[0], newValue]);
      }
    },
    [initialRange, onChange, value]
  );

  const onSliderValueChanged = useCallback(
    (event: Event, value: number | number[]) => {
      if (typeof value === 'number') {
        return;
      } else {
        onChange(value);
      }
    },
    [onChange]
  );

  return (
    <Stack>
      <TextField
        fullWidth
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {open ? <KeyboardArrowUpRounded /> : <KeyboardArrowDownRounded />}
            </InputAdornment>
          ),
          sx: { pr: 4 },
        }}
        label={label}
        onClick={onInputClicked}
        value={value
          .map((number, index) =>
            index === 0 ? formatNumber(number) : ` - ${formatNumber(number)}`
          )
          .join('')}
      />
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        onClose={onPopOverClosed}
        open={open}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
      >
        <Stack direction="column" m={8} pt={9} spacing={6}>
          <Stack direction="row">
            <Slider
              max={initialRange[1]}
              min={initialRange[0]}
              onChange={onSliderValueChanged}
              step={1000}
              sx={{
                mx: 4,
                '.MuiSlider-thumb': {
                  backgroundColor: 'background.default',
                  border: 1,
                  borderColor: 'divider',
                  boxShadow: defaultBoxShadow,
                  height: 28,
                  width: 28,
                  ':after': {
                    backgroundColor: 'primary.main',
                    content: "''",
                    height: 8,
                    width: 8,
                  },
                },
                '.MuiSlider-valueLabel': {
                  backgroundColor: '#111455',
                  borderRadius: 4,
                  py: '6px',
                },
              }}
              value={isEmpty(value) ? initialRange : value}
              valueLabelDisplay="on"
              valueLabelFormat={(label) => formatNumber(label)}
            />
          </Stack>
          <Stack direction="row" spacing={4}>
            <TextField
              InputLabelProps={{ shrink: true }}
              inputProps={{ inputMode: 'numeric' }}
              label="Min kald"
              onChange={onMinValueChanged}
              value={value[0] ?? ''}
            />
            <TextField
              InputLabelProps={{ shrink: true }}
              inputProps={{ inputMode: 'numeric' }}
              label="Max kald"
              onChange={onMaxValueChanged}
              value={value[1] ?? ''}
            />
          </Stack>
        </Stack>
      </Popover>
    </Stack>
  );
};
