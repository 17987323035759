import React from 'react';

export const DoubleArrowIcon: React.FC<{ pointsUp: boolean }> = ({
  pointsUp,
}) => {
  const colorEnabled = '#2931D6';
  const colorDisabled = '#1A1A1A';
  const opacityEnabled = '1';
  const opacityDisabled = '0.2';

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="14"
      fill="none"
      viewBox="0 0 12 14"
    >
      <g>
        <path
          d="M5.293.707L1.707 4.293C1.077 4.923 1.523 6 2.414 6h7.172c.89 0 1.337-1.077.707-1.707L6.707.707a1 1 0 00-1.414 0z"
          fill={pointsUp ? colorEnabled : colorDisabled}
          opacity={pointsUp ? opacityEnabled : opacityDisabled}
        ></path>
        <path
          d="M5.293 13.293L1.707 9.707C1.077 9.077 1.523 8 2.414 8h7.172c.89 0 1.337 1.077.707 1.707l-3.586 3.586a1 1 0 01-1.414 0z"
          fill={pointsUp ? colorDisabled : colorEnabled}
          opacity={pointsUp ? opacityDisabled : opacityEnabled}
        ></path>
      </g>
    </svg>
  );
};
