import React, { type PropsWithChildren, useState } from 'react';
import { AuthContext } from '@/auth/AuthContext';
import type { User } from '@/auth/User';

export const AuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);

  return (
    <AuthContext.Provider value={{ user, setUser }}>
      {children}
    </AuthContext.Provider>
  );
};
