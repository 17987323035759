import { Stack } from '@mui/material';
import type { Dayjs } from 'dayjs';
import isEmpty from 'is-empty';
import React, { useCallback, useState } from 'react';
import {
  ClientSystemFilter,
  EndDateFilter,
  ModulesFilter,
  RequestsFilter,
  StartDateFilter,
} from '@/components/Filters';
import { OrganisationFilter } from '@/components/Filters/OrganisationsFilter';
import { LoadingIndicator } from '@/components/LoadingIndicator';
import { NoDataFound } from '@/components/NoResults';
import { AnalyticsTable } from '@/components/Table';
import { useAnalyticsData } from '@/hooks/useAnalyticsData';

export const OrganisationsPage: React.FC = () => {
  const [selectedClientSystems, setSelectedClientSystems] = useState<string[]>(
    []
  );
  const [selectedEndDate, setSelectedEndDate] = useState<Dayjs | null>(null);
  const [selectedModules, setSelectedModules] = useState<string[]>([]);
  const [selectedOrganisation, setSelectedOrganisation] = useState('');
  const [selectedRequestsRange, setSelectedRequestsRange] = useState<number[]>(
    []
  );
  const [selectedStartDate, setSelectedStartDate] = useState<Dayjs | null>(
    null
  );
  const { data, isLoading } = useAnalyticsData({
    clientSystems: selectedClientSystems,
    endDate: selectedEndDate,
    modules: selectedModules,
    organisation: selectedOrganisation,
    requestsRange: selectedRequestsRange,
    startDate: selectedStartDate,
  });

  const onFiltersCleared = useCallback(() => {
    setSelectedEndDate(null);
    setSelectedClientSystems([]);
    setSelectedModules([]);
    setSelectedOrganisation('');
    setSelectedRequestsRange([]);
    setSelectedStartDate(null);
  }, []);

  return (
    <Stack>
      <Stack direction="row" mt={6} spacing={4}>
        <OrganisationFilter
          selectedOrganisation={selectedOrganisation}
          setSelectedOrganisation={setSelectedOrganisation}
        />
        <StartDateFilter
          selectedStartDate={selectedStartDate}
          setSelectedStartDate={setSelectedStartDate}
        />
        <EndDateFilter
          selectedEndDate={selectedEndDate}
          setSelectedEndDate={setSelectedEndDate}
        />
        <ClientSystemFilter
          selectedClientSystems={selectedClientSystems}
          setSelectedClientSystems={setSelectedClientSystems}
        />
        <ModulesFilter
          selectedModules={selectedModules}
          setSelectedModules={setSelectedModules}
        />
        <RequestsFilter
          selectedRequestsRange={selectedRequestsRange}
          setSelectedRequestsRange={setSelectedRequestsRange}
        />
      </Stack>
      {isLoading ? (
        <LoadingIndicator />
      ) : isEmpty(data) ? (
        <NoDataFound onClearFilters={onFiltersCleared} />
      ) : (
        <AnalyticsTable data={data} />
      )}
    </Stack>
  );
};
