import { Stack, Typography } from '@mui/material';
import React from 'react';

export const DefaultChip: React.FC<{ label: string }> = ({ label }) => (
  <Stack bgcolor="background.paper" borderRadius={4} width="fit-content">
    <Typography color="text.secondary" mx={3} my={2} variant="h4">
      {label}
    </Typography>
  </Stack>
);
