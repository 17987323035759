import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '@/auth/AuthContext';
import type { User } from '@/auth/User';
import { useLocalStorage } from '@/hooks/useLocalStorage';

export const useUser = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { user, setUser } = useContext(AuthContext);
  const { getItem, setItem, removeItem } = useLocalStorage();

  const addUser = (user: User) => {
    setUser(user);
    setItem('user', JSON.stringify(user));
  };

  const removeUser = () => {
    setUser(null);
    removeItem('user');
  };

  useEffect(() => {
    setIsLoading(true);
    if (!user?.accessToken) {
      const savedUser = getItem('user');
      if (savedUser) {
        setUser(JSON.parse(savedUser) as User);
      }
    }
    setIsLoading(false);
  }, []);

  return { user, isLoading, addUser, removeUser };
};
