import { KeyboardArrowDownRounded } from '@mui/icons-material';
import {
  Box,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import React, { useCallback, useState } from 'react';
import { ActiveChip, DefaultChip } from '@/components/Chips';
import { DoubleArrowIcon } from '@/components/Icons';
import type { AnalyticsData } from '@/hooks/useAnalyticsData';
import { formatNumber } from '@/lib/formatting/formatNumber';
import { defaultBoxShadow } from '@/lib/styles/theme';

export const AnalyticsTable: React.FC<{
  data: AnalyticsData[];
  showModules?: boolean;
  showTotalRequests?: boolean;
}> = ({ data, showModules = true, showTotalRequests = true }) => {
  const [isAscending, setIsAscending] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const onPageChanged = useCallback(
    (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
      setPage(newPage);
    },
    []
  );

  const onRowsPerPageChanged = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    },
    []
  );

  const onSortToggled = useCallback(
    () => setIsAscending(!isAscending),
    [isAscending]
  );

  return (
    <TableContainer
      component={Paper}
      sx={{
        border: 1,
        borderColor: 'divider',
        borderRadius: 8,
        boxShadow: defaultBoxShadow,
        mt: 6,
      }}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Organisation</TableCell>
            <TableCell>Anvendelsessystem</TableCell>
            {showModules && <TableCell>Aktive moduler</TableCell>}
            {showTotalRequests && (
              <TableCell>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="right"
                >
                  <IconButton onClick={onSortToggled}>
                    <DoubleArrowIcon pointsUp={isAscending} />
                  </IconButton>
                  Antal kald totalt
                </Stack>
              </TableCell>
            )}
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data
            .sort((a, b) => {
              const order = isAscending ? 1 : -1;
              return order * (a.totalRequests - b.totalRequests);
            })
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Typography variant="h4" color="text.primary">
                    {row.organisationName}
                  </Typography>
                </TableCell>
                <TableCell>{row.clientSystem}</TableCell>
                {showModules && (
                  <TableCell>
                    <Stack direction="row" spacing={1}>
                      {row.modules.map((module) => (
                        <ActiveChip
                          key={module.name}
                          label={module.name}
                          active={module.isActive}
                        />
                      ))}
                    </Stack>
                  </TableCell>
                )}
                {showTotalRequests && (
                  <TableCell>
                    <Box sx={{ display: 'flex', justifyContent: 'right' }}>
                      <DefaultChip label={formatNumber(row.totalRequests)} />
                    </Box>
                  </TableCell>
                )}
                <TableCell></TableCell>
              </TableRow>
            ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell colSpan={5}>
              <TablePagination
                component={Box}
                count={data.length}
                labelDisplayedRows={({ from, to, count }) =>
                  `${from}-${to} af ${count}`
                }
                labelRowsPerPage="Rækker pr. side:"
                onPageChange={onPageChanged}
                onRowsPerPageChange={onRowsPerPageChanged}
                page={page}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                slotProps={{
                  select: { IconComponent: KeyboardArrowDownRounded },
                }}
                sx={{ color: 'text.secondary', mx: 4 }}
              />
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};
