import type { Dayjs } from 'dayjs';
import isEmpty from 'is-empty';
import { useRef } from 'react';
import useSWR from 'swr';
import { useAuth } from '@/hooks/useAuth';
import { type ErrorResponse, request } from '@/lib/api/request';

export interface AnalyticsData {
  organisationName: string;
  type: string;
  clientSystem: string;
  modules: {
    name: string;
    isActive: boolean;
  }[];
  totalRequests: number;
}

export interface AnalyticsFilters {
  clientSystems: string[];
  endDate: Dayjs | null;
  modules: string[];
  organisation?: string;
  requestsRange?: number[];
  startDate: Dayjs | null;
}

export const useAnalyticsData = ({
  clientSystems,
  endDate,
  modules,
  organisation,
  requestsRange,
  startDate,
}: AnalyticsFilters) => {
  const ref = useRef<AbortController>();
  const { user, refreshAccessToken } = useAuth();

  const { data, isLoading } = useSWR<AnalyticsData[]>(
    [
      `${process.env.REACT_APP_API_BASE_URL}/api/requests/query`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          from: startDate?.format('YYYY-MM-DD') ?? undefined,
          to: endDate?.format('YYYY-MM-DD') ?? undefined,
          modules: isEmpty(modules)
            ? undefined
            : modules.map((name) => ({ name: name, isActive: true })),
          system_id: isEmpty(clientSystems) ? undefined : clientSystems,
          organisation_id: isEmpty(organisation) ? undefined : organisation,
          requests_range:
            isEmpty(requestsRange) || requestsRange === undefined
              ? undefined
              : {
                  from: requestsRange[0],
                  to: requestsRange[1],
                },
        }),
      },
      user?.accessToken,
    ],
    ([url, options, token]: [RequestInfo, RequestInit, string]) =>
      request(url, token, options, ref),
    {
      fallbackData: [],
      onError: (error: ErrorResponse) => {
        if (error.message === 'Expired token') {
          refreshAccessToken().catch(console.error);
        }
      },
    }
  );

  return { data: data!, isLoading };
};
