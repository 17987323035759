import type { Dayjs } from 'dayjs';
import React, { useCallback } from 'react';
import { DateInput } from '@/components/Inputs';

export const StartDateFilter: React.FC<{
  selectedStartDate: Dayjs | null;
  setSelectedStartDate: (date: Dayjs | null) => void;
}> = ({ selectedStartDate, setSelectedStartDate }) => {
  const onStartDateChanged = useCallback(
    (newDate: Dayjs | null) => {
      setSelectedStartDate(newDate);
    },
    [setSelectedStartDate]
  );

  return (
    <DateInput
      label="Startdato"
      onChange={onStartDateChanged}
      value={selectedStartDate}
    />
  );
};
