import { alpha, createTheme } from '@mui/material';

declare module '@mui/material/styles' {
  interface Palette {
    dividerDark?: string;
    dividerLight?: string;
    headerText?: string;
    hover?: string;
  }

  interface PaletteOptions {
    dividerDark?: string;
    dividerLight?: string;
    headerText?: string;
    hover?: string;
  }
}

export const defaultBoxShadow = '0px 2px 4px rgba(0, 0, 0, 0.04)';
export const strongBoxShadow = '0px 8px 20px rgba(8, 10, 43, 0.25)';
export const defaultGradient = `linear-gradient(#FFF, #EFF4F6)`;

export const theme = createTheme({
  palette: {
    background: {
      default: '#FFF',
      paper: '#EFF4F6',
    },
    divider: alpha('#080A2B', 0.14),
    dividerDark: alpha('#080A2B', 0.2),
    dividerLight: alpha('#080A2B', 0.06),
    headerText: '#295C70',
    hover: '#F6F7FD',
    grey: {
      500: '#999',
      600: '#B3B3B3',
      800: '#F2F2F2',
      900: '#FAFAFA',
    },
    primary: {
      light: '#7F84E6',
      main: '#2931D6',
      dark: '#191E80',
      contrastText: '#FFF',
    },
    secondary: {
      main: '#4499BB',
      dark: '#337B95',
      contrastText: '#FFF',
    },
    text: {
      primary: '#1A1A1A',
      secondary: '#4D4D4D',
      disabled: '#808080',
    },
  },
  shape: {
    borderRadius: 1,
  },
  spacing: [0, 2, 4, 8, 12, 16, 20, 24, 32, 40, 48, 64, 96],
  typography: {
    fontFamily: ['Noto Sans', 'sans-serif'].join(','),
    button: {
      textTransform: 'none',
    },
    h1: { fontSize: 28, fontWeight: 600 },
    h2: { fontSize: 20, fontWeight: 600 },
    h3: { fontSize: 16, fontWeight: 600 },
    h4: { fontSize: 14, fontWeight: 600 },
    h5: { fontSize: 13, fontWeight: 500 },
    h6: { fontSize: 11, fontWeight: 500 },
    body1: { fontSize: 15 },
    body2: { fontSize: 13 },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        outlinedPrimary: ({ theme }) => ({
          background: `linear-gradient(transparent, #0C117840), linear-gradient(#2B33D6, #2B33D6)`,
          borderColor: '#0C1178',
          color: theme.palette.primary.contrastText,
          ':disabled': {
            background: theme.palette.grey[800],
            border: 0,
            color: theme.palette.text.disabled,
          },
        }),
        outlinedSecondary: ({ theme }) => ({
          background: `linear-gradient(#FFF, #2931D60A)`,
          borderColor: theme.palette.dividerDark,
          boxShadow: defaultBoxShadow,
          color: theme.palette.primary.dark,
          ':hover': {
            background: theme.palette.hover,
            borderColor: theme.palette.primary.light,
          },
          ':disabled': {
            background: theme.palette.grey[900],
            color: theme.palette.text.disabled,
          },
        }),
        sizeLarge: ({ theme }) => ({
          borderRadius: 4,
          fontSize: 13,
          fontWeight: 600,
          minHeight: 48,
          minWidth: 'fit-content',
          paddingLeft: theme.spacing(7),
          paddingRight: theme.spacing(7),
        }),
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.text.disabled,
          fontSize: theme.typography.body2.fontSize,
        }),
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: ({ theme, ownerState }) => ({
          backgroundColor: ownerState.disabled
            ? theme.palette.grey[900]
            : theme.palette.background.default,
          borderColor: theme.palette.dividerDark,
          borderRadius: 4,
          fontSize: theme.typography.body2.fontSize,
          height: 50,
          minWidth: 170,
          ':hover': {
            backgroundColor: ownerState.disabled ? null : theme.palette.hover,
          },
          ':hover .MuiOutlinedInput-notchedOutline': {
            borderColor: ownerState.disabled
              ? null
              : theme.palette.primary.light,
          },
        }),
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: ({ theme }) => ({
          fontSize: theme.typography.h5.fontSize,
          fontWeight: theme.typography.h5.fontWeight,
          ':hover': {
            backgroundColor: theme.palette.hover,
          },
          '&.Mui-selected': {
            backgroundColor: 'transparent',
          },
        }),
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: ({ theme }) => ({
          color: theme.palette.primary.dark,
          right: theme.spacing(4),
        }),
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: theme.palette.background.default,
          borderRadius: 4,
          boxShadow: strongBoxShadow,
        }),
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fontSize: 22,
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.primary.dark,
        }),
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: ({ theme }) => ({
          backgroundColor: theme.palette.background.paper,
          color: alpha('#000', 0.7),
          padding: theme.spacing(4),
        }),
        root: ({ theme }) => ({
          backgroundColor: theme.palette.background.default,
          paddingBottom: theme.spacing(6),
          paddingLeft: theme.spacing(4),
          paddingRight: theme.spacing(4),
          paddingTop: theme.spacing(6),
        }),
        footer: {
          border: 0,
          padding: 0,
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        option: ({ theme }) => ({
          fontSize: theme.typography.h5.fontSize,
          fontWeight: theme.typography.h5.fontWeight,
          ':hover': {
            backgroundColor: '#F6F7FD !important',
          },
        }),
        input: ({ theme }) => ({
          height: 16,
          '::placeholder': {
            color: theme.palette.text.disabled,
            opacity: 1,
          },
        }),
        inputRoot: ({ theme }) => ({
          paddingLeft: theme.spacing(4),
        }),
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        actions: ({ theme }) => ({
          color: theme.palette.primary.dark,
        }),
        displayedRows: {
          marginLeft: 'auto',
        },
        input: ({ theme }) => ({
          color: theme.palette.primary.dark,
          fontSize: 13,
          fontWeight: 600,
          paddingRight: theme.spacing(4),
        }),
        menuItem: {
          justifyContent: 'center',
        },
        spacer: {
          display: 'none',
        },
      },
    },
  },
});
