import { Stack } from '@mui/material';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { NoResultsSection } from '@/components/NoResults';

export const NotFoundPage: React.FC = () => {
  const navigate = useNavigate();

  const onButtonClicked = useCallback(() => {
    navigate('/user-behaviour/organisations');
  }, [navigate]);

  return (
    <Stack m={11}>
      <NoResultsSection
        caption="Siden kunne ikke findes"
        buttonLabel="Gå til brugeradfærd"
        onButtonClick={onButtonClicked}
      />
    </Stack>
  );
};
