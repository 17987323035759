import { Button } from '@mui/material';
import React, { type PropsWithChildren } from 'react';

export const SecondaryButton: React.FC<
  PropsWithChildren<{ disabled?: boolean; onClick?: () => void }>
> = ({ children, disabled, onClick }) => (
  <Button
    color="secondary"
    disabled={disabled}
    fullWidth
    onClick={onClick}
    size="large"
    variant="outlined"
  >
    {children}
  </Button>
);
