import { Box, Stack, Typography } from '@mui/material';
import React from 'react';
import { SecondaryButton } from '@/components/Buttons';

export const NoResultsSection: React.FC<{
  buttonLabel?: string;
  caption: string;
  onButtonClick?: () => void;
  subTitle?: string;
}> = ({ buttonLabel, caption, onButtonClick, subTitle }) => (
  <Stack alignItems="center" height="66vh" justifyContent="center">
    <Box alt="" component="img" src="/images/no-results.svg" />
    <Typography color="text.primary" mt={7} variant="h2">
      {caption}
    </Typography>
    {subTitle && (
      <Typography color="text.secondary" mt={3} variant="body2">
        {subTitle}
      </Typography>
    )}
    {buttonLabel && (
      <Stack mt={7}>
        <SecondaryButton onClick={onButtonClick}>{buttonLabel}</SecondaryButton>
      </Stack>
    )}
  </Stack>
);
