import { Stack, Typography } from '@mui/material';
import React from 'react';
import { NoResultsSection } from '@/components/NoResults';

export const OverviewPage: React.FC = () => {
  return (
    <Stack>
      <Typography variant="h1">Overblik</Typography>
      <NoResultsSection caption="Denne feature er endnu ikke implementeret" />
    </Stack>
  );
};
