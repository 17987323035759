import {
  KeyboardArrowDownRounded,
  KeyboardArrowLeftRounded,
  KeyboardArrowRightRounded,
} from '@mui/icons-material';
import { DatePicker } from '@mui/x-date-pickers';
import type { Dayjs } from 'dayjs';
import * as React from 'react';
import { CalendarIcon } from '@/components/Icons';

export const DateInput: React.FC<{
  label: string;
  value: Dayjs | null;
  onChange: (date: Dayjs | null) => void;
}> = ({ label, value, onChange }) => {
  return (
    <DatePicker
      disableFuture={true}
      label={label}
      onChange={onChange}
      value={value}
      slots={{
        openPickerIcon: CalendarIcon,
        switchViewIcon: KeyboardArrowDownRounded,
        leftArrowIcon: KeyboardArrowLeftRounded,
        rightArrowIcon: KeyboardArrowRightRounded,
      }}
      slotProps={{
        textField: { fullWidth: true },
        day: {
          sx: {
            color: 'primary.dark',
            fontSize: 13,
            fontWeight: 600,
            ':focus': {
              backgroundColor: 'transparent',
            },
            ':hover': {
              backgroundColor: '#EAEAFB !important',
              color: 'primary.dark',
            },
          },
        },
        layout: {
          sx: {
            '.Mui-selected': {
              backgroundColor: '#2931D6 !important',
              color: 'primary.contrastText',
            },
            '.MuiDayCalendar-weekDayLabel': {
              color: 'text.disabled',
              fontSize: 11,
              fontWeight: 400,
            },
            '.MuiPickersArrowSwitcher-button': {
              color: 'primary.dark',
              ':disabled': {
                color: 'grey.600',
              },
            },
            '.MuiPickersArrowSwitcher-spacer': {
              margin: 4,
            },
            '.MuiPickersCalendarHeader-label': {
              fontWeight: 600,
            },
            '.MuiPickersCalendarHeader-switchViewButton': {
              color: 'primary.dark',
            },
            '.MuiPickersDay-today': {
              border: 1,
              borderColor: '#7F84E6 !important',
            },
          },
        },
      }}
    />
  );
};
