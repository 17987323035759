import { Stack, TextField } from '@mui/material';
import type { Dayjs } from 'dayjs';
import isEmpty from 'is-empty';
import React, { useCallback, useState } from 'react';
import { SecondaryButton } from '@/components/Buttons';
import { Dialog } from '@/components/Dialogs';
import {
  ClientSystemFilter,
  EndDateFilter,
  ModulesTabBarFilter,
  StartDateFilter,
} from '@/components/Filters';
import { LoadingIndicator } from '@/components/LoadingIndicator';
import { NoDataFound } from '@/components/NoResults';
import { AnalyticsTable } from '@/components/Table';
import { useAnalyticsData } from '@/hooks/useAnalyticsData';

export const ModulesPage: React.FC = () => {
  const initialSelectedModule = 'R1';
  const [selectedClientSystems, setSelectedClientSystems] = useState<string[]>(
    []
  );
  const [selectedEndDate, setSelectedEndDate] = useState<Dayjs | null>(null);
  const [selectedModules, setSelectedModules] = useState<string[]>([
    initialSelectedModule,
  ]);
  const [selectedStartDate, setSelectedStartDate] = useState<Dayjs | null>(
    null
  );
  const [showModuleTurnedOff, setShowModuleTurnedOff] = useState(false);
  const { data, isLoading } = useAnalyticsData({
    clientSystems: selectedClientSystems,
    endDate: selectedEndDate,
    modules: selectedModules,
    startDate: selectedStartDate,
  });

  const onButtonClicked = useCallback(() => {
    setShowModuleTurnedOff(true);
  }, []);

  const onDialogClosed = useCallback(() => {
    setShowModuleTurnedOff(false);
  }, []);

  const onFiltersCleared = useCallback(() => {
    setSelectedEndDate(null);
    setSelectedClientSystems([]);
    setSelectedModules([initialSelectedModule]);
    setSelectedStartDate(null);
  }, []);

  return (
    <Stack>
      <ModulesTabBarFilter
        selectedModules={selectedModules}
        setSelectedModules={setSelectedModules}
      />
      <Stack direction="row" mt={6} spacing={4}>
        <StartDateFilter
          selectedStartDate={selectedStartDate}
          setSelectedStartDate={setSelectedStartDate}
        />
        <EndDateFilter
          selectedEndDate={selectedEndDate}
          setSelectedEndDate={setSelectedEndDate}
        />
        <ClientSystemFilter
          selectedClientSystems={selectedClientSystems}
          setSelectedClientSystems={setSelectedClientSystems}
        />
        <SecondaryButton onClick={onButtonClicked}>
          Vis organisationer der ikke benytter modulet
        </SecondaryButton>
      </Stack>
      {isLoading ? (
        <LoadingIndicator />
      ) : isEmpty(data) ? (
        <NoDataFound onClearFilters={onFiltersCleared} />
      ) : (
        <AnalyticsTable data={data} showModules={false} />
      )}
      <Dialog
        onClose={onDialogClosed}
        open={showModuleTurnedOff}
        title="Organisationer der ikke benytter modulet"
      >
        <Stack direction="row" spacing={4}>
          <TextField
            disabled
            fullWidth
            InputLabelProps={{ shrink: true }}
            label="Modul"
            value={selectedModules[0]}
            variant="outlined"
          />
          <ClientSystemFilter
            selectedClientSystems={selectedClientSystems}
            setSelectedClientSystems={setSelectedClientSystems}
          />
        </Stack>
        {isLoading ? (
          <LoadingIndicator />
        ) : isEmpty(data) ? (
          <NoDataFound onClearFilters={onFiltersCleared} />
        ) : (
          <AnalyticsTable data={data} showTotalRequests={false} />
        )}
      </Dialog>
    </Stack>
  );
};
