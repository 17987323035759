import {
  AppBar,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
  Typography,
} from '@mui/material';
import React, { useCallback, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { UnderlinedButton } from '@/components/Buttons/UnderlinedButton';
import { ChangePasswordDialog } from '@/components/Dialogs/ChangePasswordDialog';
import { UserIcon } from '@/components/Icons/UserIcon';
import { NavBarDivider } from '@/components/NavBar/NavBarDivider';
import type { NavItem } from '@/components/NavBar/NavItem';
import { useAuth } from '@/hooks/useAuth';

export const NavBar: React.FC<{ navItems: NavItem[] }> = ({ navItems }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [dialogKey, setDialogKey] = useState(0);
  const [showChangePassword, setShowChangePassword] = useState(false);
  const open = Boolean(anchorEl);
  const location = useLocation();
  const { logout } = useAuth();

  const onChangePasswordClicked = useCallback(() => {
    setShowChangePassword(true);
  }, []);

  const onDialogClosed = useCallback(() => {
    setShowChangePassword(false);
    setDialogKey(dialogKey + 1);
  }, [dialogKey]);

  const onLogoutClicked = useCallback(() => {
    logout();
  }, [logout]);

  const onUserMenuOpened = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    []
  );

  const onUserMenuClosed = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <Stack>
      <AppBar
        color="transparent"
        position="static"
        sx={{
          borderBottom: 1,
          borderBottomColor: 'divider',
          borderRadius: 0,
          boxShadow: 0,
          height: 64,
          px: 11,
        }}
      >
        <Toolbar sx={{ padding: '0px !important' }}>
          <Box alt="FMB" component="img" height={32} src="/images/logo.png" />
          <NavBarDivider marginLeft={6} marginRight={6} />
          <Typography color="headerText" flexGrow={1} variant="h3">
            {document.title}
          </Typography>
          {navItems.map((item) => (
            <NavLink key={item.label} to={item.route}>
              <UnderlinedButton
                height={64}
                icon={item.icon}
                selected={
                  location.pathname.split('/')[1] === item.route.split('/')[1]
                }
              >
                {item.label}
              </UnderlinedButton>
            </NavLink>
          ))}
          <NavBarDivider marginLeft={7} marginRight={4} />
          <IconButton onClick={onUserMenuOpened}>
            <UserIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            onClick={onUserMenuClosed}
            onClose={onUserMenuClosed}
            open={open}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          >
            <MenuItem onClick={onChangePasswordClicked}>
              Ændre password
            </MenuItem>
            <MenuItem onClick={onLogoutClicked}>Log ud</MenuItem>
          </Menu>
          <NavBarDivider marginLeft={4} />
        </Toolbar>
      </AppBar>
      <ChangePasswordDialog
        key={dialogKey}
        onClose={onDialogClosed}
        open={showChangePassword}
      />
    </Stack>
  );
};
